import 'core-js'

import React, {ReactNode} from 'react'
import {contentfulClientCredentials} from 'config'
import {GetServerSideProps} from 'next'

import {
  addCountryCodeToPath,
  CountryCode,
  countryCodeIndicatorFromPath,
  getGlobalTextCopy,
  getRedirect,
  getServerSideContentfulPage,
} from '@invitae/nucleobase'

interface CatchAllPageProps {
  children: ReactNode
}

function CatchAllPage({children}: CatchAllPageProps) {
  return <>{children}</>
}

export const getServerSideProps: GetServerSideProps = async ctx => {
  const path = Array.isArray(ctx?.params?.path) ? ctx?.params?.path.join('/') : (ctx?.params?.path as string)
  const domain = ctx.req.headers?.host

  const [currentCountryCode, resolvedUrl] = countryCodeIndicatorFromPath(ctx.resolvedUrl)

  const isHomepage = !resolvedUrl || resolvedUrl === '/'
  const matchCountryCode = (countryCode: Lowercase<CountryCode>) => currentCountryCode?.toLowerCase?.() === countryCode

  if (isHomepage && matchCountryCode('us')) {
    return {
      redirect: {
        destination: '/',
        permanent: true,
      },
    }
  }

  /**
   * `path` is our reference to fetch the page in contentful
   * Homepage without country code doesn't have a path, then we have to use a fallback.
   * So, if we don't have a path, we consider that user is trying to reach homepage
   */
  const pathOrFallback = path || '/us' // `/us` is the homepage with `us` countr code

  const {
    page,
    countryCode = 'US',
    currentPath,
  } = await getServerSideContentfulPage({
    contentfulClientCredentials,
    path: pathOrFallback,
    usePreviewClient: !!process.env.ENABLE_CONTENTFUL_PREVIEW_MODE,
  })

  const globalTextCopies = await getGlobalTextCopy({
    contentfulClientCredentials,
    countryCode,
    usePreviewClient: !!process.env.ENABLE_CONTENTFUL_PREVIEW_MODE,
  })

  const props = {
    countryCode,
    domain,
    globalTextCopies,
    page,
  }

  /**
   * Should just return pages with sections
   * This condition is required because sometimes we just want to show a page with a determined country code
   * and we use the field `sections` as empty to control that
   */
  if (page && page?.fields?.sections) {
    return {props}
  }

  const redirectItem = await getRedirect({
    contentfulClientCredentials,
    countryCode,
    path: currentPath,
    usePreviewClient: !!process.env.ENABLE_CONTENTFUL_PREVIEW_MODE,
  })

  if (redirectItem) {
    return {
      redirect: {
        destination:
          countryCode === 'US'
            ? `/${redirectItem.fields.redirectTo}`
            : addCountryCodeToPath(countryCode, redirectItem.fields.redirectTo),
        permanent: true,
      },
    }
  }

  /**
   * handle 500 page, if we found it on the URL return errorCode as props
   */
  if (ctx?.resolvedUrl === '/internal-server-error') {
    return {
      props: {
        errorCode: 500,
      },
    }
  }

  return {
    // returns the default 404 page with a status code of 404
    notFound: true,
  }
}

export default CatchAllPage
